//ONLOAD
jQuery(document).ready(function( $ ){

	// COOKIE BAR
	cookiebar_activate = (typeof cookiebar_activate !== 'undefined')? cookiebar_activate : null;
	if(cookiebar_activate) {
		$.cookieBar(cookiebar_activate);
		//ANALYTICS DATA
		if(jQuery.cookieBar('cookies')){load_google_analitics();}
	}
/*
	//MOMENTS SETUP
	moment_activate = (typeof moment_activate !== 'undefined')? moment_activate : null;
	if(moment_activate) {
		moment.locale('hr');
		moment.locale('hr', moment_activate);
	}
	*/

	// Bootstrap Popovers
	$(function () {
	  $('[data-toggle="popover"]').popover();
	});

 $("[data-fancybox]").fancybox({
 		// Options will go here
 	});


	$('.navbar li.dropdown .nav-link[aria-expanded="true"]').on('click', function(event) {
		event.preventDefault();
		window.location.href = this.href;
	});





});
